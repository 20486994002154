// Import our CSS
import "@/scss/app.scss";

import jQuery from 'jquery';
Object.assign(window, { $: jQuery, jQuery });

jQuery(function($) {

    $(document).on('click', '.menu-toggler', function () {
        var btn = $(this);
        let $menuWidth;
        btn.toggleClass('active');
        $('.nav-icon').toggleClass('open');
        $('#nav-drawer').toggleClass('open');
        if ($('#nav-drawer').hasClass('open')) {
            $menuWidth = $('#nav-drawer').outerWidth();
            $('#nav-drawer').css({
                '-webkit-transform': 'translateX(0)'
            }).attr('aria-expanded', true);
        } else {
            $menuWidth = 0;
            $('#nav-drawer').css({
                '-webkit-transform': 'translateX(100%)',
                'transform': 'translateX(100%)',
            }).attr('aria-expanded', false);
        }
        $('body').toggleClass('menu-active');
        $('body #wrapper').css({
            'left': -$menuWidth
        });

    });

    getHeaderHeight();


    $(document).on('click', '.acc-title', function () {
        let $this = $(this);
        if ($this.hasClass('active')) {
            $this.next('.acc-content').slideUp();
            $this.removeClass('active');
        } else {
            $('.acc-content').slideUp();
            $('.acc-title').removeClass('active');
            $this.addClass('active');
            $this.next('.acc-content').slideDown();
        }
    });

    $(document).on('click', '.tab-label', function () {
        let $this = $(this);
        if ($this.hasClass('active')) {
            $this.next('.tab-content').stop().slideUp();
            $this.removeClass('active');
        } else {
            $('.tab-content').slideUp();
            $('.tab-label').removeClass('active');
            $this.addClass('active');
            $this.next('.tab-content').stop().slideDown();
        }
    });

    $(document).on("click", '.has-submenu-side', function () {
        let $this = $(this);
        if ($this.hasClass('active')) {
            $this.find('ul').slideUp();
            $this.removeClass('active');
        } else {
            $('.has-submenu-side ul').slideUp();
            $('.has-submenu-side').removeClass('active');
            $this.addClass('active');
            $this.find('ul').slideDown();
        }
    });

    $( window ).on( "resize", function() {
        getHeaderHeight();
    });

    function getHeaderHeight() {
        let heightWindow = $(window).height();
        let width = $(window).width();
        let heightHeader = $('#header').outerHeight();

        $('#nav-drawer').css({
            'height': heightWindow ,
        });

        $('.cart-summary').css({
            'top': heightHeader
        });

        if ($('#hero-main').length) {
            if (width >= 1024) {
                $('#hero-main').css({
                    'max-height': "calc(100vh - " + heightHeader + "px)"
                })
            } else {
                $('#hero-main').css({
                    'max-height': "initial"
                })
            }
        }
    }
});

window.setCookie = function(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

window.getCookie = function(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}